<template>
	<div style="width: 1200px;min-height: 200px;margin: auto;" v-html="honour">调接口方案</div>
    <!-- <div>
        <div id="honourCompany">企业荣誉</div>
        <div id="honourCompanyImg">
            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div> 

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>

            <div class="CompanyHonourOne">
                <img src="../../../src/assets/关于我们/资质4.png">
                <div class="honourCompanyText">高新技术企业</div>
            </div>
        </div>

        <div id="honourSoftware">软件著作</div>
        <div id="honourSoftwareImg">
            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>
            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>
            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>
            <div class="honourSoftwareOne">
                <img src="../../../src/assets/关于我们/资质-1.png">
                <div class="honourSoftwareText">高新技术企业</div>
            </div>

        </div>

    </div> -->
</template>

<script>


export default {
  name: 'honour',
  components: {
    
  },
  data(){
  	  return{
  		  honour:'',
  	  }
  },
  created() {
  	  this.$axios.get(
  	  this.$baseUrl+'index.php/Index/Index/newscontent',{
  	  		  params:{
  	  			  cId:33,
  				  pId:30,
  	  		  }
  	  }
  	  )
  	  .then(res => {
  	  		  // console.log("res",res.data.Data[0].aContent);
  			  this.honour=res.data.Data[0].aContent;
			  debugger
  	  })
  	  .catch(e => {
  	      console.log(e)
  	  })
   },
  // created() {
  // 	  this.$axios.get(
  // 	  this.$baseUrl+'index.php/Index/Index/showdetail',{
  // 	  		  params:{
  // 	  			  aId:1096,
  // 	  		  }
  // 	  }
  // 	  )
  // 	  .then(res => {
  // 	  		  // console.log("res",res.data.Data[0].aContent);
  // 			  this.honour=res.data.Data[0].aContent;
		// 	  debugger;
  // 	  })
  // 	  .catch(e => {
  // 	      console.log(e)
  // 	  })
  // }
}
</script>

<style scoped>
#honourCompany{
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666666;
    line-height: 24px;
    margin-left: 360px;
    margin-bottom: 36px;
}
.CompanyHonourOne{
    float: left;
    margin-left: 7px;
    margin-bottom: 26px;
}
#honourCompanyImg{
    width: 1212px;
    margin: auto;
    /* background: khaki; */
    overflow: hidden;
}
.honourCompanyText{
    width: 276px;
    height: 14px;
    margin-top: 11px;
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    text-align: center;
}
#honourSoftware{
    margin-left: 360px;
    margin-top: 27px;
    margin-bottom: 30px;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666666;
}
#honourSoftwareImg{
    margin: auto;
    width: 1266px;
    overflow: hidden;
    /* background: greenyellow; */
}
.honourSoftwareText{
    font-size: 20px;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 400;
    color: #707070;
    width: 197px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    margin-top: 20px;
}
.honourSoftwareOne{
    float: left;
    margin:0px 27px 40px;
}
</style>
