<template>
    <div>
		<div v-html="resData" style="width: 1200px;margin: auto;">
			<!-- {{$route.params.id}} -->
		</div>
<!--        <div id="informationDetailsTitle">河海科技“电网自然灾害预警平台”成电网系统“黑科技”{{res.Data[0].aTitle}}</div>
        <div id="informationDetailsYear"> 2022/04/01</div>
        <div id="informationDetailsText">“山西电网自然灾害监测预警平台”是湖北河海科技发展有限公司受山西电科院委托开发的一款产品，近日该产品被媒体争相报道，3月17日的国家电网报称其助力线路山火隐患治理，3月21日的中国能源报更是称之为“黑科技”，为山西电网防山火发挥了重要作用。</div>
        <div id="informationDetailsImg">
            <div><img src="../../../src/assets/newsOne.jpg"></div>
            <div><img src="../../../src/assets/newsTwo.jpg"></div>
        </div> -->
        <!-- <div id="informationDetailsFoot"> -->
            <!-- <div>上一篇：喜讯：河海科技入选2020年度“瞪羚企业”{{cName}}</div> -->
            <!-- <div>下一篇：中国气象局部署气候监测预测业务建设 持续推进研究型气候业务发展</div> -->
        <!-- </div> -->
    </div>
</template>

<script>


export default {
  name: 'informationDetails',
  components: {
    
  },
  data () {
      return {
          aId:303,
		  resData:"<div></div>",
		  cName:"",
		  // resaTitle:"河海科技“电网自然灾害预警平台”成电网系统“黑科技”",
		  // resaDate:"2022/04/01",
      }
  },
  created(){
	  this.resData=this.$store.state.content
		console.log("新闻详情Created3")
		this.$bus.$on('helloNews',(data)=>{
			console.log("我是新闻详情组件，我收到数据了22",data)
			this.resData=data.aContent
	  })
  },
  mounted() {
	  debugger
	  // this.resData=this.$parent.testData
		  this.$bus.$on('homePageNews',(data)=>{
		  this.resData=data
		  		// this.$parent.testData=data
		  console.log(this.resData,"4---a")
		  		debugger
		  })
	  
	  console.log(this.resData,"4---b")
  	console.log("新闻详情mounted5")
  },
  destroyed() {
  	console.log("销毁了6")
  }
  
  }
</script>

<style scoped>
#informationDetailsTitle{
    margin: 100px auto auto;
    text-align: center;

    font-size: 24px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
}
#informationDetailsYear{
    margin: 40px auto auto;
    text-align: center;

    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
}
#informationDetailsText{
    margin: 51px auto 36px;
    /* text-align: center; */
    width: 1200px;
}
#informationDetailsImg{
    width: 1200px;
    margin: auto auto 50px;
    overflow: hidden;
}
#informationDetailsImg div{
    width: 600px;
    height: 910px;
    float: left;
}
#informationDetailsImg div img{
    width: 100%;
    height: 100%;
}
#informationDetailsFoot{
    height: 130px;
    width: 1200px;
    padding-top: 28px;
    margin: auto;
    border-top: 1px solid #C4C4C4;;
}
#informationDetailsFoot div{
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
}
</style>
