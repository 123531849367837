<template>
        <div id="solutionPosition">
			<div id="solutionTwo">当前位置&nbsp;&nbsp;>&nbsp;&nbsp;{{$route.query.title}}</div>
        </div>
    
</template>

<script>


export default {
  name: 'lujin',
  data (){
    return {
      name:'关于我们 > 公司简介'
    }
  },
  components: {
    
  },
  mounted(){
    console.log('2');
    this.$bus.$on('hello',(data)=>{
      console.log('我是lujin组件，收到了数据',data),
      this.name=data
    })
  },
  beforeDestroy(){
    this.$bus.$off('hello')
  }
}
</script>

<style scoped>
#solutionPosition{
    height: 40px;
    /* width:1903px; */
	margin: auto;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
    line-height: 40px;
}
#solutionTwo{
	width: 1200px;
	margin: auto;
}
</style>
