//该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'
// import { component } from 'vue/types/umd'
//引入一级路由组件
import aboutUs from '../components/aboutUs.vue'
import cloudPicture from '../components/cloudPicture.vue'
import Cooperation from '../components/Cooperation.vue'
import FooterR from '../components/FooterR.vue'
import homePage from '../components/homePage.vue'
import Navigation from '../components/Navigation.vue'
import News from '../components/News.vue'
import Solution from '../components/Solution.vue'
//引入关于我们的二级路由
import companyIntroduction from '../secondComponents/aboutWe/companyIntroduction'
import honour from '../secondComponents/aboutWe/honour'
import location from '../secondComponents/aboutWe/location'
import recruitment from '../secondComponents/aboutWe/recruitment'
//引入解决方案的二级路由
import solutionDetails from '../secondComponents/solutionPlan/solutionDetails'
import solutionProgramme from '../secondComponents/solutionPlan/solutionProgramme'
//引入合作案例及伙伴的二级路由
import caseAndPartner from '../secondComponents/caseAndPartner'
import caseDetails from '../secondComponents/caseDetails'
//引入新闻资讯的二级路由
import companyDynamic from '../secondComponents/new/companyDynamic'
import informationDetails from '../secondComponents/new/informationDetails'
//创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {
            path:'/aboutUs',
            component:aboutUs,
            children:[
                {
                    path:'/',
                    component:companyIntroduction,
                },
                {
                    path:'honour',
                    component:honour,
                },
                {
                    path:'location',
                    component:location,
                },
                {
                    path:'recruitment',
                    component:recruitment,
                },
            ]
        },
        {
            path:'/cloudPicture',
            component:cloudPicture
        },
        {
            path:'/Cooperation',
            component:Cooperation,
            children:[
                {
                    path:'/',
                    component:caseAndPartner,
                },
                {
                    path:'caseDetails',
                    component:caseDetails,
                }
            ]
        },
        {
            path:'/FooterR',
            component:FooterR
        },
        {
            path:'/',
            component:homePage
        },
        {
            path:'/Navigation',
            component:Navigation
        },
        {
            path:'/News',
            component:News,
            children:[
                {
                    path:"/",
                    component:companyDynamic
                },
                {
                    path:"informationDetails",
                    component:informationDetails
                }
            ]
        },
        {
            path:'/Solution',
            component:Solution,
            children:[
                {
                    path:'/',
                    component:solutionProgramme
                },
                {
                        path:'solutionDetails',
                        component:solutionDetails
                }
            ]
        }
    ]
})

