<template>
  <div>
    <div id="newsOne">
      <img src="../../src/assets/新闻banner.jpg">
    </div>
    <lujin/>
    <!-- <newsGuidance/> -->
	<router-view></router-view>
	<!-- <router-view></router-view> -->
    <!-- <companyDynamic/> -->
    <!-- <informationDetails/> -->
  </div>
</template>

<script>
import newsGuidance from '../../src/secondComponents/new/newsGuidance.vue'
import lujin from '../../src/secondComponents/lujin.vue'
import companyDynamic from '../../src/secondComponents/new/companyDynamic.vue'
import informationDetails from '../../src/secondComponents/new/informationDetails.vue'

export default {
  name: 'News',
  components: {
    lujin,
    newsGuidance,
    companyDynamic,
    informationDetails
  },
  mounted() {
  	  window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
#newsOne{
  width: 1903px;
  margin: auto;
  font-size: 0;
}
#newsOne img{
  width: 100%;
}
</style>
