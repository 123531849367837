<template>
    <div>
        <div id="solution">
            <!-- <img src="../../src/assets/解决方案/banner.jpg"> -->
        </div>
        <!-- <div id="solutionPosition">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            当前位置&nbsp;&nbsp;>&nbsp;&nbsp;解决方案
        </div> -->
        <lujin/>
        <!-- <solutionProgramme/> -->
        <router-view></router-view>
        <!-- <solutionDetails/> -->
    </div>
</template>

<script>
import lujin from '../../src/secondComponents/lujin.vue'
import solutionProgramme from '../../src/secondComponents/solutionPlan/solutionProgramme.vue'
import solutionDetails from '../../src/secondComponents/solutionPlan/solutionDetails.vue'

export default {
  name: 'Solution',
  components: {
    lujin,
    solutionProgramme,
    solutionDetails
  },
  mounted() {
  	  window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
#solution{
    overflow-x:hidden;
    /* width: 1903px; */
	height: 350px;
	margin: auto;
	background: url(../assets/解决方案/banner.jpg) no-repeat;
	background-position: center;
    /* width: 100%; */
/* 去掉div和img下方的边界 */
    font-size: 0px;
}
#solution img{
	width: 100%;
}

/* #solutionPosition{
    height: 40px;
    width:1903px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    line-height: 40px;
} */

</style>
