<template>
</template>

<script>


export default {
  name: 'cloudPicture',
  components: {
    
  }
}
</script>

<style scoped>

</style>
