<template>
    <div id="newsGuidanceOne">
        <router-link to="?title=新闻资讯 > 公司动态"><div class="newsGuidanceTwo" @click="sedCidOne()">公司动态</div></router-link>
        <!-- <router-link to="?title=新闻资讯 > 产品动态"><div class="newsGuidanceTwo" @click="sedCidTwo()" style="border-left:none;">产品动态</div></router-link> -->
        <router-link to="?title=新闻资讯 > 行业动态"><div class="newsGuidanceTwo" @click="sedCidThree()" style="border-left:none;">行业动态</div></router-link>
        <router-link to="?title=新闻资讯 > 业务公告"><div class="newsGuidanceTwo" @click="sedCidFour()" style="border-left:none;">业务公告</div></router-link>
    </div>
</template>

<script>


export default {
  name: 'newsGuidance',
  data(){
	  return{
		  
	  }
  },
  components: {
    
  },
  created() {
  	this.$axios.get(
  	this.$baseUrl+'index.php/Index/Index/newslist',{
  			  params:{
  				  cId:15,
  				  pId:2
  			  }
  	}
  	)
  	.then(res => {
  			  this.$bus.$emit('helloNewsList',res.data.Data,res.data.totalpage)
  					  debugger
  			  console.log("helloNewsList",res)
  	})
  	.catch(e => {
  	    console.log(e)
  	})
  },
  methods:{
	  //公司动态
	  sedCidOne(){
		  this.$axios.get(
		  this.$baseUrl+'index.php/Index/Index/newslist',{
		  		  params:{
		  			  cId:15,
		  			  pId:2
		  		  }
		  }
		  )
		  .then(res => {
		  		  this.$bus.$emit('helloNewsList',res.data.Data,res.data.totalpage)
				  debugger
		  		  console.log("helloNewsList",res)
		  })
		  .catch(e => {
		      console.log(e)
		  })
	  },
	  //产品动态
	  sedCidTwo(){
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/newslist',{
	  		  		  params:{
	  		  			  cId:45,
	  		  			  pId:2
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('helloNewsList',res.data.Data,res.data.totalpage)
	  		  		  console.log("helloNewsList",res)
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  		  })
	  },
	  //行业动态
	  sedCidThree(){
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/newslist',{
	  		  		  params:{
	  		  			  cId:16,
	  		  			  pId:2
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('helloNewsList',res.data.Data,res.data.totalpage)
	  		  		  console.log("helloNewsList",res)
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  		  })
	  },
	  //业务公告
	  sedCidFour(){
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/newslist',{
	  		  		  params:{
	  		  			  cId:14,
	  		  			  pId:2
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('helloNewsList',res.data.Data,res.data.totalpage)
	  		  		  console.log("helloNewsList",res)
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  		  })
	  },
	  
	  
  }
}
</script>

<style scoped>
a{
	color: rgba(0,0,0,0.5000);
}
.router-link-exact-active div{
	background-color: #E95608;
	color: #FFFFFF;
}
#newsGuidanceOne{
    height: 40px;
    width: 1200px;
    margin: 50px auto;
    /* background: gold; */
}
#newsGuidanceOne div{
    width: 120px;
    height: 40px;
    border: 1px solid #666666;
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    float: left;
}
.newsGuidanceTwo:hover{
	background-color: #E95608;
	color: #FFFFFF;
}
</style>
