<template>
  <div id="app">
    <navigation/>
    <!-- 指定组件的呈现位置 -->
    <router-view></router-view>
    <!-- <homePage/> -->
    <!-- <Solution/> -->
    <!-- <Cooperation/> -->
    <!-- <aboutUs/> -->
    <!-- <News/> -->
    <FooterR/>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import FooterR from './components/FooterR.vue'
import homePage from './components/homePage.vue'
import Solution from './components/Solution.vue'
import News from './components/News.vue'
import Cooperation from './components/Cooperation.vue'
import cloudPicture from './components/cloudPicture.vue'
import aboutUs from './components/aboutUs.vue'



export default {
  name: 'App',
  components: {
    Navigation,
    FooterR,
    homePage,
    Solution,
    News,
    Cooperation,
    cloudPicture,
    aboutUs
  }
}
</script>

<style>
#app{
  margin:0px;
  padding:0px;
}
</style>
