<template>
	<div style="width: 1200px;min-height: 200px;margin: auto;">
		<div id="recruitmentOne">湖北河海科技发展有限公司招聘</div>
		<div id="recruitmentEmail">请把简历发送至： lj@hbhhkj.com</div>
		<div id="recruitmentConnection">收到您的简历后，我们会第一时间与您联系！</div>
		
		<div v-for="(item,index) in recruitment" :key="index">
			<div class="recruitmentTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{recruitment[index].aTitle}}
				<div class="recruitmentDetail" @click="toggleShopShowOne(index)">查看详情&nbsp;&nbsp;<img src="../../../src/assets/关于我们/多边形 2.png"></div>
			</div>
			<!-- <div class="recruitmentText" v-show="show(index)"> -->
			<div v-if="index==0">
				<div class="recruitmentText" v-show="shopShowOne" v-html="recruitment[0].aContent">
				</div>
			</div>
			<div v-else-if="index==1">
				<div class="recruitmentText" v-show="shopShowTwo" v-html="recruitment[1].aContent">
				</div>
			</div>
			<div v-else-if="index==2">
				<div class="recruitmentText" v-show="shopShowThree" v-html="recruitment[2].aContent">
				</div>
			</div>
			<div v-else-if="index==3">
				<div class="recruitmentText" v-show="shopShowfour" v-html="recruitment[3].aContent">
				</div>
			</div>
			<div v-else-if="index==4">
				<div class="recruitmentText" v-show="shopShowFive" v-html="recruitment[4].aContent">
				</div>
			</div>
			<div v-else-if="index==5">
				<div class="recruitmentText" v-show="shopShowSix"  v-html="recruitment[5].aContent">
				</div>
			</div>
			<div v-else-if="index==6">
				<div class="recruitmentText" v-show="shopShowSeven"  v-html="recruitment[6].aContent">
				</div>
			</div>
			<div v-else-if="index==7">
				<div class="recruitmentText" v-show="shopShowEight"  v-html="recruitment[7].aContent">
				</div>
			</div>
			<div v-else-if="index==8">
				<div class="recruitmentText" v-show="shopShowNine"  v-html="recruitment[8].aContent">
				</div>
			</div>
			<div v-else-if="index==9">
				<div class="recruitmentText" v-show="shopShowTen"  v-html="recruitment[9].aContent">
				</div>
			</div>

			

		</div>
		
	</div>
 <!--   <div>
        <div id="recruitmentOne">湖北河海科技发展有限公司招聘</div>
        <div id="recruitmentEmail">请把简历发送至： lj@hbhhkj.com</div>
        <div id="recruitmentConnection">收到您的简历后，我们会第一时间与您联系！</div>
        <div>
            <div class="recruitmentTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.WEB前端开发工程师 (高级)
                <div class="recruitmentDetail" @click="toggleShopShowOne">查看详情&nbsp;&nbsp;<img src="../../../src/assets/关于我们/多边形 2.png"></div>
            </div>
            <div class="recruitmentText" v-show="shopShowOne">
                <div class="recruitmentDIV">
                    <div>薪资待遇：12-18k（具体面议）</div>
                    <div style="text-align:center;">工作地点：武汉市</div>
                    <div style="text-align:end;">发布时间：2022/04/01</div>
                </div>
                <div class="recruitmentAssignmentRequest">
                    <span>【任职要求】</span><br>
                    1、专科及以上学历，计算机或软件相关专业，三年及以上工作经验；<br>
                    2、熟悉PC端web应用和移动端web应用（小程序、H5页面、APP等）；<br>
                    3、熟练掌握HTML、CSS、Less/Sass、JavaScript、Echarts、BootStrap等技术；<br>
                    4、熟练掌握前端常见开发框架，如Vue.js、React.js,对前端组件化、模块化有一定理解；<br>
                    5、熟练掌握ElementUI、Anti Design UI、Vant UI、等至少一种开源UI框架；<br>
                    6、熟练使用ajax，axios等实现前后端数据交互；<br>
                    7、具备面向对象编程思想，熟练运用ES6；<br>
                    8、对前端技术有持续的热情，能够主动研究相关新技术，具有较强的学习能力；<br>
                    9、良好的协调沟通能力和团队合作精神，执行力强，有强烈的责任心；<br>
                    10、有地理信息系统（GIS）开发经验，掌握openlayers、leaflet或supermap优先。
                </div>
            </div>
        </div>

        <div>
            <div class="recruitmentTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.软件开发工程师 (应届生)
                <div class="recruitmentDetail" @click="toggleShopShowTwo">查看详情&nbsp;&nbsp;<img src="../../../src/assets/关于我们/多边形 2.png"></div>
            </div>
            <div class="recruitmentText" v-show="shopShowTwo">
                <div class="recruitmentDIV">
                    <div>薪资待遇：12-18k（具体面议）</div>
                    <div style="text-align:center;">工作地点：武汉市</div>
                    <div style="text-align:end;">发布时间：2022/04/01</div>
                </div>
                <div class="recruitmentAssignmentRequest">
                    <span>【任职要求】</span><br>
                    1、专科及以上学历，计算机或软件相关专业，三年及以上工作经验；<br>
                    2、熟悉PC端web应用和移动端web应用（小程序、H5页面、APP等）；<br>
                    3、熟练掌握HTML、CSS、Less/Sass、JavaScript、Echarts、BootStrap等技术；<br>
                    4、熟练掌握前端常见开发框架，如Vue.js、React.js,对前端组件化、模块化有一定理解；<br>
                    5、熟练掌握ElementUI、Anti Design UI、Vant UI、等至少一种开源UI框架；<br>
                    6、熟练使用ajax，axios等实现前后端数据交互；<br>
                    7、具备面向对象编程思想，熟练运用ES6；<br>
                    8、对前端技术有持续的热情，能够主动研究相关新技术，具有较强的学习能力；<br>
                    9、良好的协调沟通能力和团队合作精神，执行力强，有强烈的责任心；<br>
                    10、有地理信息系统（GIS）开发经验，掌握openlayers、leaflet或supermap优先。
                </div>
            </div>
        </div>

        <div>
            <div class="recruitmentTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.信息系统硬件实施工程师 (应届生)
                <div class="recruitmentDetail" @click="toggleShopShowThree">查看详情&nbsp;&nbsp;<img src="../../../src/assets/关于我们/多边形 2.png"></div>
            </div>
            <div class="recruitmentText" v-show="shopShowThree">
                <div class="recruitmentDIV">
                    <div>薪资待遇：12-18k（具体面议）</div>
                    <div style="text-align:center;">工作地点：武汉市</div>
                    <div style="text-align:end;">发布时间：2022/04/01</div>
                </div>
                <div class="recruitmentAssignmentRequest">
                    <span>【任职要求】</span><br>
                    1、专科及以上学历，计算机或软件相关专业，三年及以上工作经验；<br>
                    2、熟悉PC端web应用和移动端web应用（小程序、H5页面、APP等）；<br>
                    3、熟练掌握HTML、CSS、Less/Sass、JavaScript、Echarts、BootStrap等技术；<br>
                    4、熟练掌握前端常见开发框架，如Vue.js、React.js,对前端组件化、模块化有一定理解；<br>
                    5、熟练掌握ElementUI、Anti Design UI、Vant UI、等至少一种开源UI框架；<br>
                    6、熟练使用ajax，axios等实现前后端数据交互；<br>
                    7、具备面向对象编程思想，熟练运用ES6；<br>
                    8、对前端技术有持续的热情，能够主动研究相关新技术，具有较强的学习能力；<br>
                    9、良好的协调沟通能力和团队合作精神，执行力强，有强烈的责任心；<br>
                    10、有地理信息系统（GIS）开发经验，掌握openlayers、leaflet或supermap优先。
                </div>
            </div>
        </div>

        <div>
            <div class="recruitmentTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.人工智能算法工程师
                <div class="recruitmentDetail" @click="toggleShopShowfour">查看详情&nbsp;&nbsp;<img src="../../../src/assets/关于我们/多边形 2.png"></div>
            </div>
            <div class="recruitmentText" v-show="shopShowfour">
                <div class="recruitmentDIV">
                    <div>薪资待遇：12-18k（具体面议）</div>
                    <div style="text-align:center;">工作地点：武汉市</div>
                    <div style="text-align:end;">发布时间：2022/04/01</div>
                </div>
                <div class="recruitmentAssignmentRequest">
                    <span>【任职要求】</span><br>
                    1、专科及以上学历，计算机或软件相关专业，三年及以上工作经验；<br>
                    2、熟悉PC端web应用和移动端web应用（小程序、H5页面、APP等）；<br>
                    3、熟练掌握HTML、CSS、Less/Sass、JavaScript、Echarts、BootStrap等技术；<br>
                    4、熟练掌握前端常见开发框架，如Vue.js、React.js,对前端组件化、模块化有一定理解；<br>
                    5、熟练掌握ElementUI、Anti Design UI、Vant UI、等至少一种开源UI框架；<br>
                    6、熟练使用ajax，axios等实现前后端数据交互；<br>
                    7、具备面向对象编程思想，熟练运用ES6；<br>
                    8、对前端技术有持续的热情，能够主动研究相关新技术，具有较强的学习能力；<br>
                    9、良好的协调沟通能力和团队合作精神，执行力强，有强烈的责任心；<br>
                    10、有地理信息系统（GIS）开发经验，掌握openlayers、leaflet或supermap优先。
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>


export default {
  name: 'recruitment',
  components: {
    
  },
  data () {
      return {
          shopShowOne:true,
          shopShowTwo:false,
          shopShowThree:false,
          shopShowfour:false,
          shopShowFive:false,
          shopShowSix:false,
          shopShowSeven:false,
          shopShowEight:false,
          shopShowNine:false,
          shopShowTen:false,
		  recruitment:'',
      }
  },
  methods: {
  // 	show(index) {
  // 		this.array.splice(index,1,!this.array[index])
		// console.log("--------------------------")
		// console.log(this.array[index])
		// return 1
  // 	}
  },
  created() {
  	  this.$axios.get(
  	  this.$baseUrl+'index.php/Index/Index/newslist',{
  	  		  params:{
  	  			  cId:32,
  				  pId:30,
  	  		  }
  	  }
  	  )
  	  .then(res => {
  	  		  // console.log("res",res.data.Data[0].aContent);
  			  this.recruitment=res.data.Data
			  debugger
  	  })
  	  .catch(e => {
  	      console.log(e)
  	  })
   },
  // created() {
  // 	  this.$axios.get(
  // 	  'http://localhost:82/index.php/Index/Index/newscontent',{
  // 	  		  params:{
  // 	  			  cId:7,
		// 		  pId:0
  // 	  		  }
  // 	  }
  // 	  )
  // 	  .then(res => {
  // 	  		  // console.log("res",res.data.Data[0].aContent);
  // 			  this.recruitment=res.data.Data[0].aContent;
  // 			  debugger;
  // 	  })
  // 	  .catch(e => {
  // 	      console.log(e)
  // 	  })
  // },
  methods:{
      toggleShopShowOne (index) {
          // this.shopShowOne=! this.shopShowOne
		  // this.array[index]= !this.array[index]
		  if(index==0){
			  this.shopShowOne=!this.shopShowOne
			  this.recruitment[0].aId
		  }else if(index==1){
			  this.shopShowTwo=!this.shopShowTwo
		  }else if(index==2){
			  this.shopShowThree=!this.shopShowThree
		  }else if(index==3){
			  this.shopShowfour=!this.shopShowfour
		  }else if(index==4){
			  this.shopShowFive=!this.shopShowFive
		  }else if(index==5){
			  this.shopShowSix=!this.shopShowSix
		  }else if(index==6){
			  this.shopShowSeven=!this.shopShowSeven
		  }else if(index==7){
			  this.shopShowEight=!this.shopShowEight
		  }else if(index==8){
			  this.shopShowNine=!this.shopShowNine
		  }else if(index==9){
			  this.shopShowTen=!this.shopShowTen
		  }
		  debugger
		  
      },
      toggleShopShowTwo () {
          this.shopShowTwo=! this.shopShowTwo
      },
      toggleShopShowThree (){
          this.shopShowThree=! this.shopShowThree
      },
      toggleShopShowfour (){
          this.shopShowfour=! this.shopShowfour
      }
  }
}
</script>

<style scoped>
#recruitmentOne{
    margin-top: 37px;
    margin-bottom: 43px;
    height: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
}
#recruitmentEmail{
    color: #E95608;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold;
    height: 30px;
    width: 1200px;
    line-height: 30px;
    text-align:center;
    margin: auto;
    font-weight: bold;
}
#recruitmentConnection{
    height: 26px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    width: 1200px;
    text-align: center;
    margin: 10px auto 80px;
}
.recruitmentTitle{
    width: 1200px;
    height: 80px;
    background: #F5F5F5;
    border: 1px solid #C5C5C5;
    margin: auto;
    box-sizing: border-box;

    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 80px;

}
.recruitmentDetail{
    float:right;margin-right:40px;font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #0552A3;
    line-height: 80px;
    height: 80px;
}
.recruitmentText{
    width: 1200px;
    height: 530px;
    border: 1px solid #C5C5C5;
    border-top: none;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
	padding-top: 10px;
}
.recruitmentDIV{
    margin:auto 40px;
    height: 80px;
}
.recruitmentDIV div{
    width: 372px;
    height: 80px;
    float: left;
    /* margin-left: 40px; */
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 80px;
    box-sizing: content-box;
    /* text-align: center; */
}
.recruitmentAssignmentRequest{
    width: 1120px;
    margin: auto;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
}
.recruitmentAssignmentRequest span{
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 30px;
}
</style>
