<template>
	<div style="width: 1200px;min-height: 200px;margin: auto;" v-html="caseData">
		22222222222222222222222222
	</div>
<!--    <div>
        <div id="caseDetailsTitle">河海民航气象信息服务系统</div>
        <div id="caseDetailsText">
            <div id="caseDetailsImg"><img src="../../src/assets/caseDetail.png"></div>
            <div id="caseDetailsP">        
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;民航气象信息服务同民航的经济效益和安全有着密不可分的联系。随着民航运输业的不断发展，对民航气象信息服务的多样性、实时性、准确性提出了更为高标准的要求。就民航气象科学自
                身而言，各种民航气象探测技术迅猛的发展，采集到的民航气象观测信息的数量和种类也不断增加。往往用户都会在同一个时段集中检索使用这些气象数据信息，传统的气象服务在当今大量的新
                需求、高标准的要求下已经不能胜任。
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;河海民航气象服务系统借助计算机网络技术，在现有气象服务的基础上，采用浏览器/服务器（B/S）的系统架构，方便用户随时随地通过接入网络直接使用，实现了客户端零维护；扩展方
                便，只需要分配一个账号而无需安装任何专用软件即可使用。在原有点对点地为专业用户提供规范服务的基础上，为民航运行的各参与方提供全面、及时的航空气象信息。可根据不同用户的需求
                定制显示气象服务信息，实现与运行标准紧密结合的精细化、个性化、实时化服务。
            </div>
        </div>
    </div> -->
</template>

<script>


export default {
  name: 'caseDetails',
  data(){
	  return {
		  caseData:'',
	  }
  },
  components: {
    
  },
  created(){
	  debugger
  	  this.$bus.$on('DataTwo',(data)=>{
		  this.$baseUrl
		  debugger
  		  // 请求第一篇文章
  		  this.$axios.get(
  		  this.$baseUrl+'index.php/Index/Index/showdetail',{
  		  		  params:{
  		  			  aId:data,
  		  		  }
  		  }
  		  )
  		  .then(res => {
  		  		this.caseData=res.data.Data[0].aContent;
  				debugger;
  		  })
  		  .catch(e => {
  		      console.log(e)
			  debugger
  		  })
  	  })
  },
  mounted() {
  	debugger
  }
}
</script>

<style scoped>
#caseDetailsTitle{
    text-align: center;
    font-size: 24px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
    margin: 50px auto;
}
#caseDetailsText{
    width: 1200px;
    margin: auto;

    /* background: darkkhaki; */
}
#caseDetailsImg{
    font-size: 0;
}
#caseDetailsP{
    margin-top: 50px;
    margin-bottom: 134px;
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 36px
}
</style>
