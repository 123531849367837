<template>
    <div>
        <div id="solutionDetailsHead">
            <div>
                 <div id="solutionDetailsOne">类型:</div>
                 <div id="solutionDetailsTwo">{{aviation[0].cName}}</div>
            </div>
           
            <div>
                <div id="solutionDetailsThree">系统:</div>
                <div id="solutionDetailsFour" >
                    <div class="solutionDetailsTitle" @click="countAId($event)" v-for="(item,dex) in aviation" :class="{active:currentIndex==dex}">{{item.aTitle}}</div>
<!--                    <div class="solutionDetailsTitle">河海机场气象预报编制发布系统</div>
                    <div class="solutionDetailsTitle">河海机场气候志数据处理软件</div>
                    <div class="solutionDetailsTitle">河海机场气象观测年月总簿制作软件</div>
                    <div class="solutionDetailsTitle">河海机场气候志数据处理软件</div>
                    <div class="solutionDetailsTitle">河海机场气象观测年月总簿制作软件</div> -->
                </div>
            </div>
        </div>

		<div class="boxImg" v-html="Content" style="width: 1200px;margin: auto auto 100px;min-height: 200px;">
			
		</div>
		
       <!-- <div>
            <div style="margin-bottom:40px;text-align:center;font-size: 24px;font-family: Source Han Sans CN-Bold, Source Han Sans CN;font-weight: bold;color: #666666;">方案概述</div>
            <div id="solutionDetailsSummary"v-html="Content">河海民航气象服务系统借助计算机网络技术，在现有气象服务的基础上，采用浏览器/服务器（B/S）的系统架构，方便用户随时随地通过接入网络直接使用，实现了客户端零维护；扩展方便，只需要分配一个账号而无需安装任何专用软件即可使用。在原有点对点地为专业用户提供规范服务的基础上，为民航运行的各参与方提供全面、及时的航空气象信息。可根据不同用户的需求定制显示气象服务信息，实现与运行标准紧密结合的精细化、个性化、实时化服务。</div>
        </div>

        <div>
            <div style="margin-bottom:40px;text-align:center;font-size: 24px;font-family: Source Han Sans CN-Bold, Source Han Sans CN;font-weight: bold;color: #666666;">方案优势</div>
            <div id="solutionDetailsAdvantage"></div>
        </div>

        <div>
            <div style="margin-bottom:40px;text-align:center;font-size: 24px;font-family: Source Han Sans CN-Bold, Source Han Sans CN;font-weight: bold;color: #666666;">相关产品</div>
            <div id="solutionDetailsProduct">
                    <div class="solutionDetailsProductImg">
                        <img src="../../../src/assets/解决方案/1.jpg">
                        <div>地温观测站</div>
                    </div>

                    <div class="solutionDetailsProductImg">
                        <img src="../../../src/assets/解决方案/2.jpg">
                        <div>便携式自动气象站</div>
                    </div>

                    <div class="solutionDetailsProductImg">
                        <img src="../../../src/assets/解决方案/3.jpg">
                        <div>九要素自动气象站</div>
                    </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
  name: 'solutionDetails',
  components: {
    
  },
  data(){
	  return{
		  aviation:[{"cName":''}],
		  Content:"",
		  currentIndex:'',
	  }
  },
  beforeCreate(){
	  this.$bus.$on('AviationWeather',(data)=>{
		  this.aviation=data;
		  debugger
		  // 请求第一篇文章
		  this.$axios.get(
		  this.$baseUrl+'index.php/Index/Index/showdetail',{
		  		  params:{
		  			  aId:this.aviation[0].aId,
		  		  }
		  }
		  )
		  .then(res => {
		  		console.log("res",res.data.Data[0].aContent)
		  		this.Content=res.data.Data[0].aContent;
				debugger;
		  })
		  .catch(e => {
		      console.log(e)
		  })
	  })
  },
  methods:{
	  countAId(e){
		  for(var obj in this.aviation){
			  if(this.aviation[obj].aTitle==e.currentTarget.innerHTML){
				  this.currentIndex=obj;
				  this.$axios.get(
				  this.$baseUrl+'index.php/Index/Index/showdetail',{
				  		  params:{
				  			  aId:this.aviation[obj].aId,
				  		  }
				  }
				  )
				  .then(res => {
				  		console.log("res",res.data.Data[0].aContent)
						this.Content=res.data.Data[0].aContent
				  })
				  .catch(e => {
				      console.log(e)
				  })
			  }
		  }
	  }
  },
}
</script>

<style scoped>
.solutionDetailsTitle{
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #0552A3;
    float: left;
    /* width: 224px; */
    margin: 10px;
    padding-left:10px;
    padding-right: 10px;
    /* border: 1px solid black; */
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.solutionDetailsTitle:hover{
	background-color: #E95608;
	color: #FFF;
}
.active{
	background-color: #E95608;
	color: #FFF;
}
#solutionDetailsOne{
    width: 55px;
    height: 63px;

    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 63px;
    float: left;
}
#solutionDetailsTwo{
    width: 1130px;
    height: 63px;

    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 63px;
    float: left;
    margin-left: 10px;
}
#solutionDetailsThree{
    width: 55px;
    /* height: 126px; */

    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 63px;
    float: left;
}
#solutionDetailsFour{
    width: 1140px;
    /* height: 126px; */
    float: left;
}
/* 开启BFC */
#solutionDetailsFour::after{
	content:'';
	display:block;
	clear: both;
}
#solutionDetailsHead{
    width: 1200px;
    /* height: 190px; */
    /* background: gold; */
    border-bottom: 1px solid rgba(0,0,0,0.3000);
    margin: auto auto 100px;
}
/* 开启BFC */
#solutionDetailsHead::after{
	content:'';
	display:block;
	clear: both;
}
#solutionDetailsSummary{
    width: 1127px;
    margin: auto auto 138px;

    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
}
#solutionDetailsAdvantage{
    width: 1127px;
    /* 暂时设置的高度 */
    height: 542px;
    background: #EEEEEE;
    /* 暂时设置的高度 */

    margin: auto auto 100px;

    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
}
#solutionDetailsProduct{
    width: 1230px;
    margin: auto auto 64px;
    overflow: hidden;
}
.solutionDetailsProductImg{
    float: left;
    margin: auto 15px auto;
}
.solutionDetailsProductImg div{
    width: 380px;

    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-top: 20px;
}
</style>
