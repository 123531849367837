<template>
    <div id="topp">
		<div id="bottomTwo">
			<div class="middlee" id="middleOne">
				<div class="topOne one">{{footer.comName}}</div>
				<div class="one" id="oneImg"><img src="../assets/首页/二维码.png"></div>
				<div class="one" id="oneText">扫一扫关注我们</div>
			</div>
			<div class="middlee" id="middleTwo">
				<a style="text-decoration: none;" href="#"><div class="topOne two">首页</div></a>
				<a style="text-decoration: none;" href="#/Solution?title=解决方案"><div class="two">解决方案</div></a>
				<a style="text-decoration: none;" href="#/Cooperation?title=合作案例及伙伴"><div class="two">合作案例及伙伴</div></a>
				<a style="text-decoration: none;" href="#/News?title=新闻资讯 > 公司动态"><div class="two">新闻资讯</div></a>
				<a style="text-decoration: none;" href="#/aboutUs?title=关于我们 > 公司简介"><div class="two">关于我们</div></a>
			</div>
			<div class="middlee" id="middleThree">
				<div class="topOne three">邮政:&nbsp;&nbsp;{{footer.post}}</div>
				<div class="three">电话:&nbsp;&nbsp;{{footer.tel}}</div>
				<div class="three">传真:&nbsp;&nbsp;{{footer.fax}}</div>
				<div class="three">Email:&nbsp;&nbsp;{{footer.email}}</div>
				<div class="three">网址:&nbsp;&nbsp;https://www.hbhhkj.com</div>
				<div class="three">地址:&nbsp;&nbsp;{{footer.comAddr}}</div>
			</div>
			<!-- <div id="bottomm">{{footer.icp}}<a style="text-decoration: none;color: rgba(255,255,255,0.5);" href="/index.php/Admin/Login/login" target="_blank">管理员登录</a></div> -->
		</div>
		<div id="bottomm">
		{{footer.icp}}
		<!-- <a style="text-decoration: none;color: rgba(255,255,255,0.5);" href="/index.php/Admin/Login/login" target="_blank">管理员登录</a> -->
		</div>
	</div>
</template>

<script>


export default {
  name: 'Footer',
  data(){
	  return{
		 footer:'', 
	  }
  },
  components: {
    
  },
  created() {
  	this.$axios.get(
  	this.$baseUrl+'index.php/Index/Index/common'
  	)
  	.then(res=>{
  			 this.footer=res.data.Data[0]
  	})
  	.catch(e=>{
  			 console.log(e);
  	})
  }
}
</script>

<style scoped>

#topp{
    height:320px;
    /* width:1903px; */
	margin: auto;
    background: #606060;
	
}
#bottomTwo{
	height:320px;
	width:1200px;
	margin: auto;
	background: #606060;
	overflow: hidden;
	/* position:absolute; */
	/* margin-left: -952px; */
	/* left: 50%; */
}
.middlee{
    height:280px;
    width:400px;
    float:left;
}
#middleOne{
    /* margin-left:365px; */
}
#middleTwo{
    /* margin-left:52px; */
}
#middleThree{
    /* margin-left:28px; */
}
#bottomm{
    /* width: 1903px; */
    height: 40px;
    background: #4D4D4D;  
    /* position:absolute; */
    /* bottom:0px; */
    text-align: center;
    line-height: 40px;
	/* margin-left: -960px; */
	/* left: 50%; */
	color: rgba(255,255,255,0.5);
}

.one{
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
}
#oneImg{
    margin-top:48px;
}
#oneText{
    font-size:12px;
    color: rgba(255, 255, 255, 0.5);
}
.two{
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height:43px;
}
.two:hover{
	color: #E95608;
}
.three{
    font-size: 13px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
}
.topOne{
    margin-top:42px;
    line-height: 36px;
}
</style>
