<template>
	<div style="margin: auto;">
		<div id="guidanceOne">
			<router-link to="/aboutUs/?title=关于我们 > 公司简介" @click.native="sendlujinNameOne" exact><div>公司简介</div></router-link>
			<router-link to="/aboutUs/honour?title=关于我们 > 荣誉资质" @click.native="sendlujinNameTwo"><div style="border-left:none;">荣誉资质</div></router-link>
			<router-link to="/aboutUs/recruitment?title=关于我们 > 人才招聘" @click.native="sendlujinNameThree"><div style="border-left:none;">人才招聘</div></router-link>
			<router-link to="/aboutUs/location?title=关于我们 > 联系我们" @click.native="sendlujinNameFour"><div style="border-left:none;">联系我们</div></router-link>
		</div>
	</div>
</template>

<script>


export default {
  name: 'News',
  components: {
    
  },
  data() {
      return {
          nameCompanyIntroduction:'关于我们 > 公司简介',
          nameHonour:'关于我们 > 荣誉资质',
          nameRecruitment:'关于我们 > 人才招聘',
          nameLocation:'关于我们 > 联系我们',
      }
  },
  methods:{
      sendlujinNameOne(){
          this.$bus.$emit('hello',this.nameCompanyIntroduction)
      },
      sendlujinNameTwo(){
          this.$bus.$emit('hello',this.nameHonour)
      },
      sendlujinNameThree(){
          this.$bus.$emit('hello',this.nameRecruitment)
      },
      sendlujinNameFour(){
          this.$bus.$emit('hello',this.nameLocation)
      },
  }
}
</script>

<style scoped>
a{
	color: rgba(0,0,0,0.5000);
}
.router-link-active div{
	background-color: #E95608;
	color: #FFFFFF;
}
#guidanceOne{
    height: 40px;
	width: 1200px;
	margin: auto;
    /* margin-left: 360px; */
    margin-top: 40px;
    margin-bottom: 63px;
    /* background: gold; */
}
#guidanceOne div{
    width: 120px;
    height: 40px;
    border: 1px solid #666666;
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    float: left;
}
#guidanceOne div:hover{
	background-color: #E95608;
	color: #FFFFFF;
}
</style>
