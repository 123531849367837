<template>
    <div id="bigCase">
        <div>
        <div id="case">
          <br>
          <h1>合作案例</h1>
          <h5>CASE</h5>
          <div id="cooperationCase">

              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">
                <div class="cooperationCaseImg" @click="sedData(36,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/1.png">
                </div>
                <div class="cooperationCaseText">宜昌机场集团气象工程</div>
                </router-link>
              </li>

              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(37,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/10.png">
                </div>
                <div class="cooperationCaseText">陆水防汛调度决策支持系统</div>
                </router-link>

              </li>

              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(38,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/11.png">
                </div>
                <div class="cooperationCaseText">山西森林防火气象服务系统</div>
                </router-link>

              </li>
              <!-- <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(39,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/4.png">
                </div>
                <div class="cooperationCaseText">中南地区气象综合训练平台</div>
                </router-link>

              </li> -->

              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(40,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/5.png">
                </div>
                <!-- <div class="cooperationCaseText">河海气象卫星数据综合显示与交互分析系统</div> -->
                <div class="cooperationCaseText">武汉市气象局综合气象观测业务平台</div>
                </router-link>

              </li>

              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(41,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/6.png">
                </div>
                <div class="cooperationCaseText">广西电力葵花FY-2双星系统</div>
                </router-link>

              </li>
              <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(42,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/7.png">
                </div>
                <div class="cooperationCaseText">山西电网自然灾害监测预警平台</div>
                </router-link>

              </li>

              <!-- <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(43,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/8.png">
                </div>
                <div class="cooperationCaseText">河海气象卫星数据综合显示与交互分析系统</div>
                </router-link>

              </li> -->

              <!-- <li>
                <router-link to="/Cooperation/caseDetails?title=合作案例及伙伴 > 案例详情">

                <div class="cooperationCaseImg" @click="sedData(44,35)">
                  <img src="../../src/assets/合作案例及伙伴/合作案例/9.png">
                </div>
                <div class="cooperationCaseText">民航气象信息服务系统</div>
                </router-link>

              </li> -->

          </div>

        </div>
        <div id="partner">
          <br>
          <h1>合作伙伴</h1>
          <h5>PARTNER</h5>
          <div id="partnerMiddle">
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/武大.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/华科.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/省气.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/hubei.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/电网.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/nf电网.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/西部机场.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/东部机场.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/山西电网.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/气象服务.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/南海预报.png">
            </li>

            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/东海预报.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/中南.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/西北.png">
            </li>
            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/东北.png">
            </li>

            <li>
              <img src="../../src/assets/合作案例及伙伴/合作伙伴/华东.png">
            </li>
          

          </div>
        </div>
      </div>
    </div>
</template>

<script>


export default {
  name: 'caseAndPartner',
  components: {
    
  },
  created() {
  	
  },
  mounted() {
  	  window.scrollTo(0, 0);
  },
  methods:{
  	  sedData(cid,pid){
  		  this.$axios.get(
		  // 'http://localhost:82/index.php/Index/Index/showdetail'
  		  this.$baseUrl+'index.php/Index/Index/piclist',{
  		  		  params:{
  		  			 cId:cid,
  		  			 pId:pid
  		  		  }
  		  }
  		  )
  		  .then(res => {
			  var aa=res.data.Data[0].aId;
  		  		  this.$bus.$emit('DataTwo',res.data.Data[0].aId)
  				  debugger
  		  })
  		  .catch(e => {
  		      console.log(e)
  		  })
  	  }
  },
}
</script>

<style scoped>
a{
	text-decoration: none;
}
#bigCase{
	/* width: 1903px; */
	margin: auto;
}
#partner{
  /* width: 1903px; */
  height: 780px;
  background: #F5F5F5;
}
#partnerMiddle{
  width: 1240px;
  /* height: 535px; */
  /* background: darkkhaki; */
  margin: auto;
  overflow: hidden;
}
#partnerMiddle li{
  list-style: none;
  float: left;
}
#partnerMiddle img{
  margin: -15px -15px;
}
h1{
    font-size: 36px;
    text-align: center;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;  
    font-weight: 500;
    color: #000000;
}
h5{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
}

#case{
  height: 900px;
  /* width: 1903px; */
  /* background: darkcyan; */
}
#cooperationCase{
  width: 1230px;
  height: 960px;
  margin: auto;
  /* background: darkgoldenrod; */
}
#cooperationCase li{
  height:270px;
  width: 380px;
  list-style: none;
  float: left;
  margin: 25px 15px;
}
#cooperationCase li:hover{
	background: url("../assets/合作案例及伙伴/案例悬停.png") no-repeat;
	background-position: center center;
}
#cooperationCase li:hover .cooperationCaseText{
	color: #E95608;
}
.cooperationCaseImg{
  width: 380px;
  height: 230px;
  font-size: 0px;
}
.cooperationCaseText{
  width: 380px;
  height: 40px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  text-align: center;
}
</style>
