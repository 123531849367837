<template>
    <div id="solutionPicture">
            <div id="solutionMiddlePicture">
                <!-- 单个图片和文字 -->
                
                <div class="solutionPictureAndText" @click="sedData(10,4)">
                    <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">
                    <img src="../../../src/assets/解决方案/航空气象.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">航空气象</div>
                        <div class="solutionPictureTwo">为各地民航和通航机场气象台提供先进的民航气象观测，高精度气象预报，通信和信息服务等业务系统，平台，工具和仪器设备。</div>
                    </div>
                    </router-link>
                </div>
                
                <!-- 单个图片和文字 -->
               
                <div class="solutionPictureAndText" @click="sedData(11,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">
                    <img src="../../../src/assets/解决方案/水利水电.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">水利水电</div>
                        <div class="solutionPictureTwo"> 为水利、水电和输变电网络等企事业单位提供水雨情和自然灾害实况监测、高精度气象预警预报和信息服务等业务系统、平台、工具和仪器设备。</div>
                    </div>
                    </router-link>
                </div>
            
                <!-- 单个图片和文字 -->
                <div class="solutionPictureAndText" @click="sedData(27,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">

                    <img src="../../../src/assets/解决方案/风力发电.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">风力发电</div>
                        <div class="solutionPictureTwo">为风电场提供高精度气象预报和短期与超短期功率预测服务，满足电网调度需求，提高电场效益；同时提供高精度气象灾害监测与预警服务，保障发电、输电稳定，保护设备资产与运维人员安全。</div>
                    </div>
                     </router-link>
                </div>
                <!-- 单个图片和文字 -->
                <div class="solutionPictureAndText" @click="sedData(28,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">

                    <img src="../../../src/assets/解决方案/光伏发电.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">光伏发电</div>
                        <div class="solutionPictureTwo">为光伏电场提供高精度气象预报和短期与超短期功率预测服务，满足电网调度需求，提高电场效益；同时提供高精度气象灾害监测与预警服务，保障发电、输电稳定，保护设备资产与运维人员安全。</div>
                    </div>
                     </router-link>
                </div>
                <!-- 单个图片和文字 -->
                <div class="solutionPictureAndText" @click="sedData(22,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">

                    <img src="../../../src/assets/解决方案/交通气象.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">交通气象</div>
                        <div class="solutionPictureTwo">为交通运输企业、政府部门、硬件及系统集成商、图商等多种客户提供高时空分辨率精细化天气实况监测和分级警报产品、道路交通气象灾害分级预警和临近预报产品。融合实时道路信息，实现动态分析交通运行状况。可根据不同业务需求，提供定制化服务。</div>
                    </div>
                     </router-link>
                </div>
                <!-- 单个图片和文字 -->
                <div class="solutionPictureAndText" @click="sedData(23,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">

                    <img src="../../../src/assets/解决方案/森林防火.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">森林防火</div>
                        <div class="solutionPictureTwo">综合利用Aqua、Terra、NPP、NOVV、FY3、FY4、葵花等多源卫星数据，基于先进火点识别算法，实现指定区域火情实时监测，并结合气象实况及预报资料预测火情发展趋势。根据地理及当前气象条件，预测林区火险风险等级，为森林防火提供科学依据。</div>
                    </div>
                     </router-link>
                </div>
                <!-- 单个图片和文字 -->
                <div class="solutionPictureAndText" @click="sedData(24,4)">
                     <router-link to="/Solution/solutionDetails?title=解决方案 > 方案详情">

                    <img src="../../../src/assets/解决方案/公众气象.jpg">
                    <div class="solutionPictureText">
                        <div class="solutionPictureOne">公众气象</div>
                        <div class="solutionPictureTwo">开发先进的气象观测、预报、通信和信息服务业务系统，为各级气象台站、公众气象服务中心、气象院校科研院所提供气象数据处理系统、平台、工具的开发外包服务和技术支持。</div>
                    </div>
                     </router-link>
                </div>


            </div>
        </div>
</template>

<script>


export default {
  name: 'solutionProgramme',
  components: {
    
  },
  data(){
	  return {
		  AviationWeather:"",
	  }
  },
  methods:{
	  
	  sedData(cid,pid){
		  this.$axios.get(
		  this.$baseUrl+'index.php/Index/Index/piclist',{
		  		  params:{
		  			  cId:cid,
		  			  pId:pid
		  		  }
		  }
		  )
		  .then(res => {
		  		  this.$bus.$emit('AviationWeather',res.data.Data)
				  // this.AviationWeather=res.data.Data
				  debugger
		  })
		  .catch(e => {
		      console.log(e)
			  debugger
		  })
	  }
  }
}
</script>

<style scoped>
a{
	text-decoration: none;
}
#solutionPicture{
    height: 1708px;
    /* width: 1903px; */
	margin: auto;
    /* background: gold; */
    overflow: hidden;
}
#solutionMiddlePicture{
    width: 1232px;
    height:1558px;
    margin:auto;
    /* background: white; */
    margin-top: 60px;
}

.solutionPictureText{
    width: 556px;
    height: 113px;
}
.solutionPictureAndText{
    width: 556px;
    height: 313px;
    float: left;
    margin-bottom:20px;
    border:25px solid white;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}
.solutionPictureAndText:hover{
	box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
}
.solutionPictureAndText:nth-of-type(even){
    margin-left: 20px;
}
.solutionPictureOne{
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 32px;
}
.solutionPictureTwo{
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
}
</style>
