<template>
    <div id="top">
        <div id="topImg">
            <img src="../assets/首页/logo.png">
        </div>
		<div id="NavigationPosition">
			<router-link class="tab_item" to="/" exact><div class="navigate">首页</div></router-link>
			<router-link class="tab_item" to="/Solution?title=解决方案" >
				<div style="position: relative;" class="navigate">
					解决方案&nbsp;&nbsp;
				<img class="NaImg" src="../assets/首页/多边形 1.png">
					<div id="BoxlistSolution">
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(10,4)"><img src="../assets/导航&底/解决方案/1.png"/>&nbsp;&nbsp;航空气象</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(11,4)"><img src="../assets/导航&底/解决方案/2.png"/>&nbsp;&nbsp;水利水电</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(27,4)"><img src="../assets/导航&底/解决方案/3.png"/>&nbsp;&nbsp;风力发电</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(28,4)"><img src="../assets/导航&底/解决方案/4.png"/>&nbsp;&nbsp;光伏发电</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(22,4)"><img src="../assets/导航&底/解决方案/5.png"/>&nbsp;&nbsp;交通气象</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(23,4)"><img src="../assets/导航&底/解决方案/6.png"/>&nbsp;&nbsp;森林防火</div></a>
						<a href="#/Solution/solutionDetails?title=解决方案 > 方案详情" @click.stop><div @click="sedData(24,4)"><img src="../assets/导航&底/解决方案/7.png"/>&nbsp;&nbsp;公众气象</div></a>
					</div>
				</div>
			</router-link>
			<router-link class="tab_item" to="/Cooperation?title=合作案例及伙伴" ><div class="navigate">合作案例及伙伴</div></router-link>
			<router-link class="tab_item" to="/News?title=新闻资讯 > 公司动态" ><div class="navigate" @click="sedCidOnee()">新闻资讯</div></router-link>
			<div class="navigate"><a id="cloud" target="_blank" href="http://wxyt.hbhhkj.com:808/">云图数据服务</a></div>
			<router-link class="tab_item"to="/aboutUs?title=关于我们 > 公司简介">
				<div style="position: relative;" class="navigate">
					关于我们&nbsp;&nbsp;
					<img class="NaImg" src="../assets/首页/多边形 1.png">
					<div id="Boxlist">
						<a href="#/aboutUs?title=关于我们 > 公司简介" @click.stop><div>公司简介</div></a>
						<a href="#/aboutUs/honour?title=关于我们 > 荣誉资质" @click.stop><div>资质荣誉</div></a>
						<a href="#/aboutUs/recruitment?title=关于我们 > 人才招聘" @click.stop><div>人才招聘</div></a>
						<a href="#/aboutUs/location?title=关于我们 > 联系我们" @click.stop><div>联系我们</div></a>
					</div>
				</div>
			</router-link>

			<div class="navigate" id="phone"><img src="../assets/首页/phone.png" >&nbsp;&nbsp;&nbsp;027-87411520</div>
		</div>
		
	</div>
</template>

<script>


export default {
  name: 'Navigation',
  components: {
    
  },
  methods:{
	  //公司动态
	  sedCidOnee(){
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/newslist',{
	  		  		  params:{
	  		  			  cId:15,
	  		  			  pId:2
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('helloNewsList',res.data.Data)
	  		  		  console.log("helloNewsList",res)
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  		  })
	  },
	  
	  sedData(cid,pid){
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/piclist',{
	  		  		  params:{
	  		  			  cId:cid,
	  		  			  pId:pid
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('AviationWeather',res.data.Data)
	  				  // this.AviationWeather=res.data.Data
	  				  debugger
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  			  debugger
	  		  })
	  }
  },
}
</script>

<style scoped lang="scss">
#top{
  position: relative;
  /* width:1903px; */
  height:100px;
  /* border:1px solid blue; */
  margin: auto;
}
#topImg img{
  height:61px;
}
#top div{
  height:96px;
  float:left;
  text-align:center;
  line-height: 100px;
}
#topImg{
  margin:auto 481px auto 80px; 
}
#topImg img{
	vertical-align: middle;
}
.navigate{
  height: 100px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  margin-right:50px;
  color: #666666;
}
.navigate img{
  vertical-align: middle;
}
.navigate:hover{
  border-bottom: #E95608 4px solid;
  color: #E95608;
  border-radius: 2px 2px 2px 2px;
  // content: url("../assets/首页/多边形 2.png");
}
.navigate:hover .NaImg{
	content: url("../assets/首页/多边形 2.png");
}
.navigate:hover #cloud{
	color: #E95608;
}
.router-link-active .navigate{ 
			border-bottom: #E95608 4px solid;
			color: #E95608;
			border-radius: 2px 2px 2px 2px;
	}
.router-link-active .NaImg{
	content: url("../assets/首页/多边形 2.png");
}
#NavigationPosition{
	position: absolute;
	right: 50px;
	@media only screen and (max-width:1500px){
		right: 0px;
	}
	/* right: 1%; */
}
#phone{
  /* position: absolute; */
  margin-left:98px; 
  @media only screen and (max-width:1500px){
  	margin-left: 0px;
  }
  /* right: 50px; */
  color:#E95608;
  font-weight: bold;
  font-size: 24px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
}
#phone:hover{
	border-bottom: white 4px solid;
}
#phone img{
  vertical-align: middle;
}
#cloud{
	text-decoration: none;
	color: #666666;
}
#BoxlistSolution{
	position: absolute;
	left: 50%;
	bottom: -100px;
	display: none;
	margin-left: -75px;
	z-index: 1000;
}
#BoxlistSolution div{
	width: 150px;
	height:50px;
	border-bottom: 1px solid rgba(0,0,0,0.5);
	background-color: #FFFFFF;
	line-height: 50px;
	color: rgba(0,0,0,0.5);
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
}
#BoxlistSolution div:hover{
	background-color: #E95608;
	color: #FFFFFF;
}
.navigate:hover #BoxlistSolution{
	display: inline-block;
}
#Boxlist{
	position: absolute;
	left: -13px;
	bottom: -100px;
	display: none;
	z-index: 1000;
}
#Boxlist div{
	width: 110px;
	height:50px;
	border-bottom: 1px solid rgba(0,0,0,0.5);
	background-color: #FFFFFF;
	line-height: 50px;
	color: rgba(0,0,0,0.5);
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
}
#Boxlist div:hover{
	background-color: #E95608;
	color: #FFFFFF;
}
.navigate:hover #Boxlist{
	display: inline-block;
}
</style>
