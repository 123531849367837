<template>
    <div>
		<div id="HomePageAside">
			<div id="imgOneHomePageAsideTwoCode" >
				<img id="imgOne" src="../assets/首页/1.png" alt="">
				<div id="HomePageAsideTwoCode"><img src="../assets/首页/二维码.png" alt=""></div>
			</div>
			<div id="imgTwoHomePageAsideTwoCode">
				<img id="imgTwo" src="../assets/首页/3.png" alt="" @click="controlPhone()">
				<div id="HomePageAsidePhone" v-show="PhoneStatus">
					<div class="HomePageTel">
						<div class="HomePageLocation">华东/西北片区</div>
						<div class="HomePagePhone">袁经理 18086068741</div>
					</div>
					
					<div class="HomePageTel">
						<div class="HomePageLocation">华北/西南/西藏片区</div>
						<div class="HomePagePhone">刘经理 18086088741</div>
					</div>
					
					<div class="HomePageTel">
						<div class="HomePageLocation">中南/新疆片区</div>
						<div class="HomePagePhone">杨经理 18086638741</div>
					</div>
					
					<div class="HomePageTel">
						<div class="HomePageLocation">东北片区</div>
						<div class="HomePagePhone">刘经理 18086108741</div>
					</div>
				</div>
			</div>
			
		</div>
				
		<!-- <div style="width: 1903px;margin: auto;position: relative;"> -->
		<div>
			<div id="carousel">
				<div class="future" style="margin-top: 180px;	margin-left: -330px;">数据平台 分析未来</div>
				<div class="future" style="margin-top: 320px;	margin-left: -380px;">METEOROLOGICAL</div>
				<!-- <img src="../../src/assets/首页/banner1.jpg"/> -->
				<!-- 轮播图片 -->
<!-- 				<img @mouseover="changeInterval(true)"
					@mouseleave="changeInterval(false)"
					v-for="item in ImgName"
					:key="item.id"
					:src="item.url"
					v-show="item.id===currentIndex"
					> -->
					<div class="carouselImg" @mouseover="changeInterval(true)"
						@mouseleave="changeInterval(false)"
						v-for="item in ImgName"
						:key="item.id"
						:style="{backgroundImage:'url('+item.url+')'}"
						v-show="item.id===currentIndex"
						>
					</div>
				<!-- 轮播图控制短横线 -->
				<div class="banner-horizontalLine">
					<ul>
						<li @click="changeImg(item.id)"
							v-for="item in ImgName"
							:key="item.id"
							:class="item.id===currentIndex? 'active':''"
						></li>
					</ul>
				</div>
			</div>
		</div>
        <div id="introduction">
            <br>
            <br>
            <br>
            <h1>公司简介</h1>
            <h5>COMPANY PROFILE</h5>
            <br>
			<div style="margin: auto; text-align:center; ">	
				<img style="width: 468px;height: 38px;margin-top: 21px;margin-bottom: 28px;" src="../../src/assets/首页/CompanyFont.png"/>
			</div>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;湖北河海科技发展有限公司是一家成立于1998年3月的高新技术企业，专业从事气象观测，预报，通信和信息服务系统等领域研究开发，工程建设和技术服务，为
                航空气象，水利电力，风力发电，光伏发电，交通气象，森林防火，公众气象等行业提供一体化专业气象解决方案。
            </p> 
            <br>
			<br>
			<br>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司是国家认证的高新技术企业，是地方政府认定的“瞪羚企业”，也是首批获得武汉市政府认定的“双软”资质企业。公司已取得质量管理体系，信息技术服务
                管理体系，知识产权管理体系认证，拥有50多件自主知识产权，公司研发，管理团队科技成果多次获得省部级科技进步奖和多项优秀软件产品奖。
            </p>
        </div>
        <div id="solution">
            <br>
            <br>
            <br>
            <h1>解决方案</h1>
            <h5>SOLUTION</h5>
            <br>
            <div id="solutionOne">
                <div class="solutionTu" style="margin-bottom:24px;"><a @click="sedData(10,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/航空气象.png" style="display:block;"></a><div class="blueKuang">航空气象</div></div>
                <div class="solutionTu" style="margin-bottom:24px;"><a @click="sedData(11,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/水利水电.png" style="display:block;"></a><div class="blueKuang">水利水电</div></div>
                <div class="solutionTu" style="margin-bottom:24px;"><a @click="sedData(27,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/风力发电.png" style="display:block;"></a><div class="blueKuang">风力发电</div></div>
                <div class="solutionTu" style="margin-right:0px;margin-bottom:24px;"><a @click="sedData(28,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/光伏发电.png" style="display:block;"></a><div class="blueKuang">光伏发电</div></div>
                <div class="solutionTu"><a @click="sedData(22,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/交通气象.png" style="display:block;"></a><div class="blueKuang">交通气象</div></div>
                <div class="solutionTu"><a @click="sedData(23,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/森林防火.png" style="display:block;"></a><div class="blueKuang">森林防火</div></div>
                <div class="solutionTu"><a @click="sedData(24,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/公众气象.png" style="display:block;"></a><div class="blueKuang">公众气象</div></div>
                <!-- <div class="solutionTu" style="margin-right:0px;"><a @click="sedData(10,4)" href="#/Solution/solutionDetails?title=解决方案 > 方案详情"><img src="../../src/assets/首页/敬请期待.png" style="display:block;"></a><div class="blueKuang">暂无</div></div> -->
                <div class="solutionTu" style="margin-right:0px;"><img src="../../src/assets/首页/敬请期待.png" style="display:block;"><div class="blueKuang">敬请期待</div></div>
            </div>
        </div>
        <div id="news">
            <br>
            <br>
            <br>
            <h1 style="color: #FFFFFF;">新闻资讯</h1>
            <h5 style="color: rgba(255,255,255,0.5000);">NEWS</h5>
            <br>
            <br>
            <div id="homePageNews">
				
				<div class="newss">
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
					<div class="homePageNewsOne" @click="push(newslist[0].aContent)">
						<span >
							<div style="overflow:hidden;">
								<div class="homePageYear">{{yearTime[0][0]}}</div>
								<img src="../../src/assets/首页/new.png">
								<div class="homePageTitle">{{newslist[0].aTitle}}</div>
							</div>
							<div>
								<div class="homePageDay">{{yearTime[0][1]}}-{{yearTime[0][2]}}</div>
								<div class="homePageNewsText">{{newslist[0].shortTag}}</div>
							</div>
						</span>
					</div>
				</router-link>
				</div>
				
				<div class="newss">
				
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
                <div class="homePageNewsOne" @click="push(newslist[1].aContent)">
                    <div style="overflow:hidden;">
                        <div class="homePageYear">{{yearTime[1][0]}}</div>
                        <img src="../../src/assets/首页/new.png">
                        <div class="homePageTitle">{{newslist[1].aTitle}}</div>
                    </div>
                    <div>
                        <div class="homePageDay">{{yearTime[1][1]}}-{{yearTime[1][2]}}</div>
                        <div class="homePageNewsText">{{newslist[1].shortTag}}</div>
                    </div>
                </div>
				</router-link>
				</div>

				<div class="newss">
					
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
                <div class="homePageNewsOne" @click="push(newslist[2].aContent)">
                    <div style="overflow:hidden;">
                        <div class="homePageYear">{{yearTime[2][0]}}</div>
                        <div style="margin-left: 90px;" class="homePageTitle">{{newslist[2].aTitle}}</div>
                    </div>
                    <div>
                        <div class="homePageDay">{{yearTime[2][1]}}-{{yearTime[2][2]}}</div>
                        <div class="homePageNewsText">{{newslist[2].shortTag}}</div>
                    </div>
                </div>
				</router-link>
				</div>

				<div class="newss">
					
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
                <div class="homePageNewsOne" @click="push(newslist[3].aContent)">
                    <div style="overflow:hidden;">
                        <div class="homePageYear">{{yearTime[3][0]}}</div>
                        <div style="margin-left: 90px;" class="homePageTitle">{{newslist[3].aTitle}}</div>
                    </div>
                    <div>
                        <div class="homePageDay">{{yearTime[3][1]}}-{{yearTime[3][2]}}</div>
                        <div class="homePageNewsText">{{newslist[3].shortTag}}</div>
                    </div>
                </div>
				</router-link>
				</div>

				<div class="newss">
					
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
                <div class="homePageNewsOne" @click="push(newslist[4].aContent)">
                    <div style="overflow:hidden;">
                        <div class="homePageYear">{{yearTime[4][0]}}</div>
                        <div style="margin-left: 90px;" class="homePageTitle">{{newslist[4].aTitle}}</div>
                    </div>
                    <div>
                        <div class="homePageDay">{{yearTime[4][1]}}-{{yearTime[4][2]}}</div>
                        <div class="homePageNewsText">{{newslist[4].shortTag}}</div>
                    </div>
                </div>
				</router-link>
				</div>

				<div class="newss">
					
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情">
                <div class="homePageNewsOne" @click="push(newslist[5].aContent)">
                    <div style="overflow:hidden;">
                        <div class="homePageYear">{{yearTime[5][0]}}</div>
                        <div style="margin-left: 90px;" class="homePageTitle">{{newslist[5].aTitle}}</div>
                    </div>
                    <div>
                        <div class="homePageDay">{{yearTime[5][1]}}-{{yearTime[5][2]}}</div>
                        <div class="homePageNewsText">{{newslist[5].shortTag}}</div>
                    </div>
                </div>
				</router-link>
				</div>
				
            </div>
			<!-- <a href="#/News?title=新闻资讯 > 公司动态"><div id="seeMore">查看更多&nbsp;&nbsp;<img src="../../src/assets/首页/jiantouBai.png"></div></a> -->
        </div>
        <div id="partner">
            <br>
            <br>
            <br>
            <h1>合作案例及伙伴</h1>
            <h5>CASE AND PARTNER</h5>
            <br>
            <br>
            <div id="partnerTu">
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/武大.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/华科.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/省气.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/hubei.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/电网.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/nf电网.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/西部机场.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/东部机场.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/气象服务.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/中南空管.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/南海预报.png" style="display:block;"></div>
                <div class="partnerT"><img src="../../src/assets/首页/合作伙伴/东海预报.png" style="display:block;"></div>

            </div>
            <a style="text-decoration: none;"  href="#/Cooperation?title=合作案例及伙伴"><div class="see">查看更多&nbsp;&nbsp;<img id="seeImg" src="../../src/assets/首页/箭头.png"></div></a>
        </div>
    </div>
</template>

<script>


export default {
  name: 'Navigation',
  components: {
    
  },
  data(){
      return {
          PhoneStatus:true,
          currentIndex :0,//当前所在图片的下标
          timer:null,//定时轮询
		  // newslist:'',
		  newslist:[ 
						{
							"aTitle":'1',
							"shortTag":'1',
						},
						{
							"aTitle":'1',
							"shortTag":'1',
						},
						{
							"aTitle":'1',
							"shortTag":'1',
						},
						{
							"aTitle":'1',
							"shortTag":'1',
						},
						{
							"aTitle":'1',
							"shortTag":'1',
						},
						{
							"aTitle":'1',
							"shortTag":'1',
						}
			         
				   ],
		  yearTime:[['2022','6','17'],['2022','6','17'],['2022','6','17'],['2022','6','17'],['2022','6','17'],['2022','6','17']],
		  ImgName:'',
		  companyDescript:'',
          imgArr:[
              {
                  id:0,
                  url:require('../../src/assets/首页/banner1.jpg'),
              },{
                  id:1,
                  url:require('../../src/assets/首页/banner2.jpg')
              },{
                  id:2,
                  url:require('../../src/assets/首页/banner3.jpg')
              }
          ]
      }
  },
  methods:{
	  push(a){
		  this.$store.commit('changeContent',a)
	  },
	  //给新闻详情页发数据
	  // sedaContent(index){
		 //  this.$bus.$emit('homePageNews',this.newslist[index].aContent)
	  // },
      //开启定时器
      startInterval(){
          //事件里的定时器应该先清除在设置，防止多次点击直接生成多个定时器
          clearInterval(this.timer)
          this.timer = setInterval(()=>{
              this.currentIndex++
              if(this.currentIndex > this.imgArr.length-1){
                  this.currentIndex = 0
              }
          },2800)
      },
      //控制短横线
      changeImg(index){
          this.currentIndex=index
      },
      //鼠标的移入移出控制
      changeInterval(val){
          if(val){
            clearInterval(this.timer)
          }else{
              this.startInterval()
          }
      },
	  //解决方案详情传数据
	  sedData(cid,pid){
	  
	  		  this.$axios.get(
	  		  this.$baseUrl+'index.php/Index/Index/piclist',{
	  		  		  params:{
	  		  			  cId:cid,
	  		  			  pId:pid
	  		  		  }
	  		  }
	  		  )
	  		  .then(res => {
	  		  		  this.$bus.$emit('AviationWeather',res.data.Data)
	  				  // this.AviationWeather=res.data.Data
	  				  // debugger
	  		  })
	  		  .catch(e => {
	  		      console.log(e)
	  		  })
	  },
      controlPhone(){
            this.PhoneStatus=!this.PhoneStatus;
      }
  },
//   进入页面后启动定时轮询
  mounted(){
      this.startInterval()
  },
  beforeDestroy() {
  	  clearInterval(this.timer)
	  this.timer=null
  },
  created() {
	  this.$axios.get(
	  this.$baseUrl+'index.php/Index/Index/newslist',{
	  		  params:{
	  			  cId:2
	  		  }
	  }
	  )
	  .then(res => {
		  this.newslist=res.data.Data
		  for(var i=0;i<6;i++){
				  this.yearTime[i]=res.data.Data[i].aDate.split("/")
		  }
		  this.yearTime
	  })
	  .catch(e => {
	      console.log(e)
	  })
  
  	this.$axios.get(
  	this.$baseUrl+'index.php/Index/Index/common'
  	)
  	.then(res=>{
		
  			 this.ImgName=res.data.img
			 this.companyDescript=res.data.Data[0].descript
  	})
  	.catch(e=>{
  			 console.log(e)
  	})
	}
}
</script>

<style scoped lang="scss">
/* //控制轮播图短横线 */
.banner-horizontalLine{
	position: absolute;
	left: 50%;
	margin-left: -75px;
	bottom: 30px;
    width: 22%;
    height:20px;
}
.banner-horizontalLine ul{
    margin: 0 auto;
    height: 100%;
    text-align: center;
	padding:0px;
}
.banner-horizontalLine ul li{
    list-style-type: none;
    display: inlint-block;
    width: 40px;
    height: 4px;
    margin: 0 5px;
    background: rgba(255,255,255,0.4000);
    border-radius: 2px 2px 2px 2px;
    float: left;
}
.active{
    background-color: #E95608 !important;
}
/* //控制轮播图短横线 */

#homePageNews{
    width: 1256px;
    margin: auto;
    background: darkorange;
}
#carousel{
    // width:100%;
    position:relative;
    height:640px;
	width: 100%;
	/* margin: auto; */
	/* margin: auto; */
    display: inline-block;
    background: lightblue;
    /* line-height:0px; */
    /* font-size: 0; */
}
.carouselImg{
	// width: 1903px;
	// width: auto;
	height: 640px;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	// display: block;

}
#carousel img{
    height:100%;
    width:100%;
    display: block;
    /* vertical-align: top; */
}
#introduction{
    /* width:1903px; */
    height:800px;
	margin: auto;
    background: #F5F5F5;
    background-image: url("../../src/assets/首页/简介bg.png");
    /* background: #F5F5F5; */
    background-size: 63% 80%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
h1{
    font-size: 36px;
    text-align: center;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    color: #000000;
}
h5{
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
}
h2{
    text-align: center;
    font-size: 36px;
    font-family: 微软简行楷;
    font-weight: 400;
    color: #E95608;
    line-height: 48px;
}
p{
    width:1138px;
    height:58px;
    line-height: 48px;
    /* margin-left: 391px; */
	margin: auto;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    color: #666666;
}
#solution{
    /* width:1903px; */
    /* width:100%; */
    height: 800px;
	margin: auto;
    /* background-color: aquamarine; */
}
#solutionOne{
    width:1201px;
    /* width:63.14%; */
    height:547px;
    /* background-color: #E95608; */
    margin: auto;
}
.solutionTu{
    width:280px;
    /* width:23.32%; */
    height:260px;
    /* height:47.54%; */
    border-radius: 6px;
    margin-right:27px ;
    display: inline-block;
    /* transition: width 3s; */
    overflow: hidden;
	position: relative;
}
.blueKuang{
	width: 280px;
	height: 50px;
	z-index: 1000;
	background-color: #E95608;
	background: #0552A3;
	border-radius: 0px 0px 6px 6px;
	opacity: 0.5;
	position: absolute;
	left: 0px;
	bottom: 0px;
	font-size: 16px;
	font-family: Source Han Sans CN-Bold, Source Han Sans CN;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 50px;
	text-align: center;
}
.solutionTu img{
    width: 100%;
    height: 100%;
    transition: all 0.6s;
}
.solutionTu img:hover{
    /* width: 320px; */
    transform: scale(1.3);

}
#news{
    /* width:1903px; */
    height:900px;
	margin: auto;
    background: #0552A3;
    background-image: url("../../src/assets/首页/新闻bg.png");
    /* background: #F5F5F5; */
    background-size: 26%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
}
#partner{
    /* width:1903px; */
    height:800px;
	margin: auto;
    background: #F5F5F5;
}
#partnerTu{
    width:1240px;
    height:369px;
    /* background: #E95608; */
    margin: auto;
}
.partnerT{
    width:340px;
    height:160px;
    display: block;
    display: inline-block;
    margin-left:-30px;
    margin-top:-30px;
    /* margin-right:27px; */
    /* margin-bottom: 23px; */
}
.partnerT img{
    width: 100%;
    height: 100%;
}
.see{
    border: 1px solid #0552A3;
    color: #0552A3;
    width: 180px;
    height:50px;
    text-align: center;;
    line-height: 50px;
    margin: auto;
    margin-top: 50px;
    border-radius: 6px;
}
.see:hover{
	background-color: #E95608;
	border: 1px solid #E95608;
	color: #FFFFFF;
}
.see:hover #seeImg{
	content: url(../assets/首页/jiantouBai.png);
}
#seeMore{
	border: 1px solid white;
	color: white;
	width: 180px;
	height:50px;
	text-align: center;;
	line-height: 50px;
	margin: auto;
	margin-top: 175px;
	margin-left: -719px;
	border-radius: 6px;
	float: left;
	/* position: relative; */
	/* top: 100px; */
	/* left: 100px; */
}
#seeMore img{
	vertical-align: middle;
}
.see img{
    vertical-align: middle;
}
.homePageNewsOne{
    width: 568px;
    height: 126px;
    /* border: 1px solid black; */
    border-bottom:1px solid rgba(255,255,255,0.2000);
    float: left;
    /* margin-bottom: 50px; */
	margin: auto 30px 30px;
	/* padding: 10px; */
	/* border-radius: 5px; */
}
.newss{
	/* background-color: #E95608; */
	float: left;
	padding-top: 20px;
	border-radius: 8px;
}
.newss:hover{
	background-color: rgba(255,255,255,0.1);
	cursor: pointer;
}
.homePageYear{
    font-size: 20px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    float: left;
    /* display: inline-block; */
    margin-right: 29px;
}
.homePageNewsOne div img{
    float: left;
}
.homePageTitle{
	height: 64px;
    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
	
    /* margin-left: 49px; */
    /* display: block; */
    float: left;
    margin-left: 40px;
	width: 396px;
	
}
.homePageDay{
    height: 57px;
    width: 134px;
    font-size: 48px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 57px;
    float: left;
    margin-top: 0px;
}
.homePageNewsText{
        /* line-height: 57px; */
    float: left;
    width: 394px;
    margin-top: 15px;
    margin-left: 33px;

    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 23px;
	/* overflow:hidden; */
}
#HomePageAside{
	z-index: 1000;
	position: fixed;
	right: 20px;
	top: 50%;
	margin-top: -65px;
	width: 64px;
}
#HomePageAsideTwoCode{
	position: absolute;
	right: 64px;
	bottom: 32px;
	display: none;
}
#imgOne:hover{
	content: url(../assets/首页/2.png);
	cursor: pointer;
}
#imgOneHomePageAsideTwoCode{
	width: 74px;
	padding-left: 10px;
}
#imgTwoHomePageAsideTwoCode{
	width: 74px;
	padding-left: 10px;
}
#HomePageAsidePhone{
	position: absolute;
	left: -155px;
    bottom: -250px;
    background:#ffffff;
	display: block;
/* 	width: 50px;
	height: 100px; */
	/* background-color: #0552A3; */
}
.HomePageLocation{
	width: 230px;
	height: 30px;
	line-height: 30px;
	background-color: white;
	text-align: center;
	
	fontSize: 16px;
	fontFamily: Source Han Sans CN-Regular, Source Han Sans CN;
	color: #666666;
}
.HomePagePhone{
	width: 230px;
	height: 30px;
	line-height: 30px;
	background-color: white;
	text-align: center;
	margin-bottom: 1px;
	color: #0552A3;
	font-weight: bold;
	
}
#imgOneHomePageAsideTwoCode:hover #HomePageAsideTwoCode{
	display: inline-block;
}
/*#imgTwoHomePageAsideTwoCode:hover #HomePageAsidePhone{
	display: inline-block;
}*/
#imgTwo:hover{
	content: url(../assets/首页/4.png);
	cursor: pointer;
}
.future{
	position: absolute;
	font-size: 80px;
	font-family: Source Han Sans CN-Bold, Source Han Sans CN;
	font-weight: bold;
	color: #FFFFFF;
	text-align: center;
	left: 50%;
	// margin-top: 285px;
}
</style>
