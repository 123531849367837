<template>
    <div>
      <div id="cooperationOne">
        <!-- <img src="../../src/assets/合作案例banner.jpg"> -->
      </div>
      <lujin/>
      <router-view></router-view>
      <!-- <caseAndPartner/> -->
      <!-- <caseDetails/> -->
     
    </div>
</template>

<script>
import lujin from '../../src/secondComponents/lujin.vue'
import caseAndPartner from '../../src/secondComponents/caseAndPartner.vue'
import caseDetails from '../../src/secondComponents/caseDetails.vue'
export default {
  name: 'Cooperation',
  components: {
    lujin,
    caseAndPartner,
    caseDetails
  }
}
</script>

<style scoped>
#cooperationOne{
  font-size: 0px;
  /* width: 1903px; */
  margin: auto;
  height: 350px;
  /* width: 100%; */
  background: url(../assets/合作案例banner.jpg) no-repeat;
  background-position: center;
}
#cooperationOne img{
  width: 100%;
}
</style>
