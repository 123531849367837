<template>
	<div>
<!-- 		<div style="width: 1200px;min-height: 200px;margin: auto;" v-html="introduce">
			调接口方案
		</div> -->
    <div>
            <div id="companyIntroductionOne">湖北河海科技发展有限公司简介</div>
            <div style="width:1200px;margin:auto;">
                <div class="companyIntroductionImg">&nbsp;&nbsp;公司概况</div>
                <div class="companyIntroductionText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;湖北河海科技发展有限公司于1998年3月成立，注册资本1000万元人民币，专业从事气象观测、预报、通信和信息服务系统等领域研究开发、工程建设和技术服务，为气象、民航、水利、电力、交通、海洋、环境和科研单位等提供研发生产、系统集成、安装调试、售后服务为一体的专业气象工程解决方案。</div>
           
                <div class="companyIntroductionImg">&nbsp;&nbsp;主营业务</div>
                <div class="companyIntroductionText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同步/极轨气象卫星地面接收处理系统，航空气象情报网络系统，自动气象观测系统，气象产品制作发布系统，气象信息综合业务平台，人工智能气象预报系统，电网自然灾害监测预警平台，水雨情及环境监测系统，气象局公共气象服务平台，气象观测场建设工程，航空、水利、电力、交通、旅游、水产、乡村和设施农业等各类专业气象信息传输与服务。</div>

                <div class="companyIntroductionImg">&nbsp;&nbsp;公司荣誉</div>
                <div class="companyIntroductionText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;经过二十多年的发展，公司荣获国家高新技术企业，被地方政府认定为“瞪羚企业”，同时具备“双软”资质，通过了ISO9001质量管理体系认证，拥有CMMI三级资质，获得了50多项软件著作权及专利证书，公司研发、管理团队科技成果多次获得省部级科技进步奖和多项优秀软件产品奖。</div>
           
                <div class="companyIntroductionImg">&nbsp;&nbsp;公司理念</div>
                <div class="companyIntroductionText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务创造价值，创新成就未来。</div>
           
            </div>
        </div>
		
        <div style="height:462px;background:#F5F5F5;overflow:hidden;margin: auto;">
            <div style="margin:74px auto auto;width:1200px;">
                <div style="width:215px;height:288px;float:left;margin-top:13px;box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3000);">
                    <img style="width:100%;height:100%;" src="../../../src/assets/关于我们/资质1.png">
                </div>
                <div style="width:476px;height:313px;float:left;">
                    <img style="width:100%;height:100%;" src="../../../src/assets/关于我们/资质2.png">
                </div>
                <div style="width:469px;height:312px;float:left;">
                    <img style="width:100%;height:100%;" src="../../../src/assets/关于我们/资质3.png">
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'companyIntroduction',
  components: {
    
  },
  data(){
	  return{
		  introduce:'',
	  }
  },
  created() {
  	  this.$axios.get(
  	  this.$baseUrl+'index.php/Index/Index/newscontent',{
  	  		  params:{
  	  			  cId:34,
  				  pId:30,
  	  		  }
  	  }
  	  )
  	  .then(res => {
  	  		  // console.log("res",res.data.Data[0].aContent);
  			  this.introduce=res.data.Data[0].aContent;
                debugger;
  	  })
  	  .catch(e => {
  	      console.log(e)
  	  })
   },
  // created() {
	 //  this.$axios.get(
	 //  'http://localhost:82/index.php/Index/Index/newscontent',{
	 //  		  params:{
	 //  			  cId:1,
		// 		  pId:0,
	 //  		  }
	 //  }
	 //  )
	 //  .then(res => {
	 //  		  // console.log("res",res.data.Data[0].aContent);
		// 	  this.introduce=res.data.Data[0].aContent;
	 //  })
	 //  .catch(e => {
	 //      console.log(e)
	 //  })
  // }
}
</script>

<style scoped>
#companyIntroductionOne{
    margin-top: 37px;
    margin-bottom: 80px;
    height: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
}
.companyIntroductionImg{
    background-image: url("../../../src/assets/关于我们/概况.png");
    height: 40px;
    background-repeat: no-repeat;
    line-height: 35px;
    color: white;
    font-size: 18px;
    margin-bottom: 30px;
}
.companyIntroductionText{
    width:1172px;
    margin:auto;
    margin-bottom: 30px;

    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
}
</style>
