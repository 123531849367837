<template>
    <div>
      <div id="aboutUsOne">
        <img src="../../src/assets/关于我们/关于banner.jpg">
      </div>
      <lujin/>
      <guidance/>
      <router-view></router-view>
      <!-- <companyIntroduction/> -->
      <!-- <honour/> -->
      <!-- <location/> -->
      <!-- <recruitment/> -->
    </div>   
</template>

<script>
import lujin from '../../src/secondComponents/lujin.vue'
import guidance from '../../src/secondComponents/aboutWe/guidance.vue'
import Honour from '@/secondComponents/aboutWe/honour.vue'
import companyIntroduction from '../../src/secondComponents/aboutWe/companyIntroduction.vue'
import location from '../secondComponents/aboutWe/location.vue'
import recruitment from '../secondComponents/aboutWe/recruitment.vue'

export default {
  name: 'aboutUs',
  components: {
    lujin,
    guidance,
    Honour,
    companyIntroduction,
    location,
    recruitment
  },
  mounted() {
  	  window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
#aboutUsOne{
  width: 1903px;
  margin: auto;
  font-size: 0;
}
#aboutUsOne img{
  width: 100%;
}
</style>
