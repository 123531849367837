<template>
	<!-- <div style="width: 1200px;min-height: 200px;margin: auto;" v-html="location"></div> -->
    <div>
        <div id="locationOne">湖北河海科技发展有限公司</div>
        <div id="localtionTEL">
            <div>&nbsp;&nbsp;&nbsp;&nbsp;华东/西北片区：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;袁经理 18086068741</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;中南/新疆片区：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨经理 18086638741</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;华北/西南/西藏片区：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;刘经理 18086088741</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;东北片区：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;刘经理 18086108741</div>
        </div>
        <div id="localtionMessage">
            <div><img src="../../../src/assets/关于我们/邮编.png">&nbsp;&nbsp;邮编:&nbsp;&nbsp;&nbsp;{{footerTwo.post}}</div>
            <div><img src="../../../src/assets/关于我们/电话.png">&nbsp;&nbsp;电话:&nbsp;&nbsp;&nbsp;{{footerTwo.tel}}</div>
            <div><img src="../../../src/assets/关于我们/传真.png">&nbsp;&nbsp;传真:&nbsp;&nbsp;&nbsp;{{footerTwo.fax}}(8014)</div>
            <div><img src="../../../src/assets/关于我们/email.png">&nbsp;&nbsp;Email:&nbsp;&nbsp;&nbsp;{{footerTwo.email}}</div>
            <div><img src="../../../src/assets/关于我们/网址.png">&nbsp;&nbsp;网址:&nbsp;&nbsp;&nbsp;https://www.hbhhkj.com</div>
            <div><img src="../../../src/assets/关于我们/地址.png">&nbsp;&nbsp;地址:&nbsp;&nbsp;&nbsp;{{footerTwo.comAddr}}</div>
        </div>
		     <div style="width:1200px;margin-left:auto;margin-right:auto;height:610px;overflow:hidden;margin-bottom:40px">
            <iframe src="./map.html" width="100%" height="100%" scrolling="no" frameborder="0" align=""></iframe>
        </div>
      </div>
</template>


<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'

export default {
  name: 'location',
  components: {
    BaiduMap
  },
  data () {
    return {
      center: {lng: 0, lat: 0},
      zoom: 3,
	  lianxi:'',
	  location:'',
	  footerTwo:'',
    }
  },
  methods: {
    handler ({BMap, map}) {
      console.log(BMap, map)
      this.center.lng = 114.398
      this.center.lat = 30.516
      this.zoom = 15
    },
  },
 created() {
	  this.$axios.get(
	  this.$baseUrl+'index.php/Index/Index/newscontent',{
	  		  params:{
	  			  cId:31,
				  pId:30,
	  		  }
	  }
	  )
	  .then(res => {
	  		  // console.log("res",res.data.Data[0].aContent);
			  this.location=res.data.Data[0].aContent;
			  debugger
	  })
	  .catch(e => {
	      console.log(e)
	  }),
	  
	  this.$axios.get(
	  this.$baseUrl+'index.php/Index/Index/common'
	  )
	  .then(res=>{
	  		 this.footerTwo=res.data.Data[0]
			 debugger
	  })
	  .catch(e=>{
	  		 console.log(e);
	  })
  },

}
</script>

<style scoped>
.bm-view {
  width: 100%;
  height: 100%;
}

#locationOne{
    margin-top: 37px;
    margin-bottom: 80px;
    height: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
}
#localtionTEL{
    width: 1220px;
    height: 160px;
    margin: auto;
}
#localtionTEL div{
    width: 590px;
    height: 60px;
    box-sizing: border-box;
    float: left;
    margin: auto 10px 20px;
    /* background: salmon; */
    border: 1px solid #C5C5C5;

    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 60px;
}
#localtionMessage{
    margin: 57px auto 20px;
    width: 1204px;
    /* background: salmon; */
    overflow: hidden;
}
#localtionMessage div{
    height: 48px;
    width: 600px;
    /* border: 1px solid black; */
    float: left;
    line-height: 48px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}
#localtionMessage div img{
    vertical-align: middle;
}
#map{
    width: 1200px;
    height: 600px;
    /* background: fuchsia; */
    margin: auto auto 65px;
}
</style>
