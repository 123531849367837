import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const mutations={
	changeContent(state,value){
		state.content=value
	}
}

//准备state--用于存储数据
const state={
	content:"文章"
}

export default new Vuex.Store({
	// actions,
	mutations,
	state,
})