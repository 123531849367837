import 'babel-polyfill'
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import {Pagination} from 'element-ui'
import axios from 'axios'
//引入store
import store from './store'


//关闭vue生产提示
Vue.config.productionTip = false
//应用插件
Vue.use(VueRouter)
Vue.use(Pagination)

new Vue({
  render: h => h(App),
  router:router,
  store,//将创建的共享数据对象，挂载到vue实例中，所有的组件，就可以直接从store中获取全局的数据了
  // axios:axios,
  beforeCreate(){
    Vue.prototype.$bus=this;//安装全局事件总线
    Vue.prototype.$axios = axios;
	  //Vue.prototype.$baseUrl='http://192.168.1.25/';//baseUrl开发时用本机ip地址，合并部署时用符号/
	  Vue.prototype.$baseUrl='/';//baseUrl开发时用本机ip地址，合并部署时用符号/
  }
}).$mount('#app')

	  

