<template>
	<div>
	<newsGuidance/>
    <div id="companyDynamicOne">							
			<div v-for="(item,index) in info">
				<router-link to="/News/informationDetails?title=新闻资讯 > 资讯详情" >
					<div class="companyDynamicTwo" @click="countCidAndPid($event)">
						<div class="companyDynamicNews">{{info[index].aTitle}}</div>
						<div class="companyDynamicTime">{{info[index].aDate}}</div>
					</div>
				</router-link>
			</div>
		
        <!-- <div class="companyDynamicTwo">
            <div class="companyDynamicNews">开始河海科技“电网自然灾害监测预警平台”成电网系统“黑科技”</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">喜讯：河海科技入选2020年度“瞪羚企业”</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">荆州机场顺利通过行业验收</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">河海科技助力社会扶贫简讯</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">公司积极落实民航局空管办《关于加强飞行气象情报质量控制工作的通知》</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">河海科技成功中标某部队9套卫星云图系统升级改造项目</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">河海科技承建的岳阳三荷机场气象工程顺利通过行业验收</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">吉林空管分局气象台领导一行来我公司考察交流</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">我公司研发的创新产品在“楚天杯”工业设计大赛中获奖</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div>

        <div class="companyDynamicTwo">
            <div class="companyDynamicNews">民航中南、华北地区空管局气象部领导一行来我司考察交流</div>
            <div class="companyDynamicTime">2022/3/21</div>
        </div> -->
        <!-- 分页位置 -->
        <div id="companyDynamicPagination" style="border: none;">
			<li class="paginationLi" style="width: 50px;margin-left: 0px;" v-on:click="getDataOne()">上一页</li>
			<li class="paginationLi" v-for="(item,index) in totalPage" :class="{'active':activeIndex==index}" v-on:click="getData(index)">
				 <!-- v-on:click="activeIndex=index" -->
				{{index+1}}
			</li>
			<li class="paginationLi" style="width: 50px;" v-on:click="getDataTwo()">下一页</li>
<!--            <el-pagination
                background
				page-size
                layout="prev, pager, next"
                :total="100"
				>
            </el-pagination> -->
		</div>
    </div>
	</div>
</template>

<script>
// import axios from axios
import newsGuidance from '../new/newsGuidance.vue'
export default {
  name: 'companyDynamic',
  components: {
	  newsGuidance
  },
  data () {
      return {
		  cid:14,
          info:null,//所有数据
		  totalPage:1,//一共的页数
		  activeIndex:0,//记录分页当前页
		  cId:15,
		  pId:2,
		  // total:10,
      }
  },
  methods:{
	  countCidAndPid(e){
		  //console.log("第一个子标签",e.currentTarget.firstElementChild.innerHTML)
		  for (var obj in this.info){
			  if(this.info[obj].aTitle==e.currentTarget.firstElementChild.innerHTML){
				  console.log(obj,this.info[obj])
				  console.log("cId",this.info[obj].cId)
				  console.log("pId",this.info[obj].pId)
				  // ----------------------
				  this.$axios.get(
				  this.$baseUrl+'index.php/Index/Index/showdetail',{
				  		  params:{
				  			  aId:this.info[obj].aId,
				  			  // aId:aa
				  		  }
				  }
				  )
				  .then(res => {
						this.$bus.$emit('helloNews',res.data.Data[0])
						console.log("res",res.data.Data[0])
						debugger
				  })
				  .catch(e => {
				      console.log(e)
				  })
				  // ---------------------------
				  //用全局事件总线发送aId
				  // this.$bus.$emit('helloNews',this.info[obj].aId)
			  }
		  }
	  },
	  getDataOne(){
		  this.info[0].cId
		  debugger
		  if(this.activeIndex>0){
			  this.activeIndex=this.activeIndex-1
			  this.$axios.get(
			  			this.$baseUrl+'index.php/Index/Index/newslist',{
			  				params:{
			  					cId:this.info[0].cId,
			  					pId:this.info[0].pId,
			  					p:this.activeIndex+1
			  				}
			  			}
			  ) 
			  .then(res=>{
			  			 this.info=res.data.Data
			  			 debugger
			  })
			  .catch(e=>{
			  			 
			  })
		  }else{
			  this.activeIndex=0
		  }
	  },
	  getDataTwo(){
	  		  if(this.activeIndex<this.totalPage-1){
	  			  this.activeIndex=this.activeIndex+1
	  			  this.$axios.get(
	  			  			this.$baseUrl+'index.php/Index/Index/newslist',{
	  			  				params:{
	  			  					cId:this.info[0].cId,
	  			  					pId:this.info[0].pId,
	  			  					p:this.activeIndex+1
	  			  				}
	  			  			}
	  			  ) 
	  			  .then(res=>{
	  			  			 this.info=res.data.Data
	  			  			 debugger
	  			  })
	  			  .catch(e=>{
	  			  			 
	  			  })
	  		  }else{
	  			  this.activeIndex=this.totalPage-1
	  		  }
	  },
	  getData(index){
		 this.activeIndex=index;
		 this.info[0].cId
		 debugger
		 this.$axios.get(
			this.$baseUrl+'index.php/Index/Index/newslist',{
				params:{
					cId:this.info[0].cId,
					pId:this.info[0].pId,
					p:this.activeIndex+1
				}
			}
		 ) 
		 .then(res=>{
			 this.info=res.data.Data
			 debugger
		 })
		 .catch(e=>{
			 
		 })
	  },
  },
  
  created (){
	  debugger;
	  this.$bus.$on('helloNewsList',(data,totalPage)=>{
	  			  console.log("我是新闻列表组件，我收到数据了",data)
	  			  this.info=data
				  this.totalPage=totalPage
				  this.activeIndex=0				
	  })
	},
mounted(){

},

}
</script>

<style scoped>
.active{
	background-color: #E95608;
	color: white;
}
.paginationLi{
	width: 36px;
	height: 36px;
	margin-left: 10px;
	border: 1px solid rgba(0,0,0,0.1);
	float: left;
	list-style: none;
	text-align: center;
	line-height: 36px;
}
.paginationLi:hover{
	background-color: #E95608;
	color: #FFFFFF;
}
#companyDynamicOne{
    width: 1200px;
    height: 648px;
    margin: auto;
    /* background: darkkhaki; */
}
.companyDynamicTwo{
    width: 1200px;
    height: 50px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
    /* background: salmon; */
    /* border: 1px solid black; */
}
.companyDynamicTwo div:hover+.companyDynamicTime{
	color: #E95608;
	
}
.companyDynamicTwo div:hover{
	color: #E95608;
}
/* .companyDynamicNews:hover{
	color: #E95608;
} */
.companyDynamicNews{
    width: 1110px;
    height: 50px;
    display: inline-block;
    font-size: 15px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 50px;
}
.companyDynamicTime{
    width: 90px;
    height: 50px;
    display: inline-block;
    font-size: 15px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 50px;
}
#companyDynamicPagination{
    width: 1200px;
    height: 36px;
    /* background: hotpink; */
    border: 1px solid black;
    margin: 50px auto auto;
}
</style>
